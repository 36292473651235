<script setup lang="ts">
    import {computed} from 'vue';
    import useCpBem from '~/composables/useCpBem';
    import CpButton from '~/components/cp/CpButton/CpButton.vue';
    import CpProductCardHome from '~/components/cp/CpProductCard/CpProductCardHome/CpProductCardHome.vue';
    import CpProductCardHomeStream
        from '~/components/cp/CpProductCard/CpProductCardHome/CpProductCardHomeStream/CpProductCardHomeStream.vue';
    import CpProductCardCatalog from '~/components/cp/CpProductCard/CpProductCardCatalog/CpProductCardCatalog.vue';
    import CpProductCardCatalogStream
        from '~/components/cp/CpProductCard/CpProductCardCatalog/CpProductCardCatalogStream/CpProductCardCatalogStream.vue';
    import CpLoader from '~/components/cp/CpLoader/CpLoader.vue';
    import type {ICpArticle, IEtaInfo} from '~/types';
    import {parsePriceInt} from '~/utils';

    const props = defineProps<{
      button?: boolean,
      buttonHref?: string,
      itemsToShow?: Array<ICpArticle>,
      itemsCatalog?: Array<ICpArticle>,
      label?: string,
      kind: 'cta4'|'cta6'|'onlyProducts',
      cardType: 'home'|'listHome'|'catalog'|'catalogList',
      etaData?: IEtaInfo | { messages: IEtaInfo['messages'] }
      removeBottomPadding?: boolean
      /** Whether to show the loader. */
      showLoader?: boolean
      /** Whether to show the "Load More" button. */
      showLoadMoreButton?: boolean
      /** Whether to show the "Previous" and "Next" buttons. */
      showPreviousNextButtons?: boolean
      /** Link to the previous page. */
      previousPageLink?: string;
      /** Link to the next page. */
      nextPageLink?: string;
    }>();

    const getCardKind = (item: ICpArticle) => {
      if (item.offer.percent && item.isDailyOffer) {
        return 'FeaturedOffer';
      } else if (item.offer.percent) {
        return 'Offer';
      } else if (item.isDailyOffer) {
        return 'Featured';
      } else if (item.eol || item.stockStatus === -1) {
        return 'Oos';
      } else if (item.offer.percent && item.eol) {
        return 'OosOffer';
      } else {
        return 'Standard';
      }
    };

    const emit = defineEmits<{
      (e:'buttonPress'): void
      (e:'scroll-end'): void
    }>();
    const limitedArray = computed(() => {
        if (props.kind === 'cta4' && props.itemsToShow) {
          return props.itemsToShow.slice(0, 4);
        } if (props.kind === 'cta6' && props.itemsToShow) {
            return props.itemsToShow.slice(0, 6);
          } else {
              return props.itemsToShow;
            }
    });
    const {b, e, em} = useCpBem('cp-section-strip');

    const showLoadMoreButton = computed(() => props.showLoadMoreButton && !props.showLoader);
    const bottomPadding = computed(() => props.removeBottomPadding ? 0 : '16px');
</script>

<template>
    <div :class="b">
        <div v-if="cardType === 'home'" :class="e('container')" data-testid="featured-products-cards">
            <CpProductCardHome
                v-for="item in limitedArray"
                :id="item.id"
                :key="item.id"
                :kind="getCardKind(item)"
                :product-type="item.isEsd ? 'esd' : 'physical'"
                :pill-large="item.isEsd && getCardKind(item) === 'Standard'"
                :pill-short="item.isEsd && getCardKind(item) !== 'Standard'"
                :rate="item.rating.total > 0"
                :rate-value="item.rating.global"
                :reviews="item.rating.total"
                :free-shipping="item.freeShipping"
                :link="item.link"
                :title="item.title"
                :has-discount="item.isOffer || item.tPrice > parsePriceInt(item.price)"
                :discount="item.offer.percent"
                :picture="item.picture"
                :image-title="item.title"
                :price="item.price"
                :t-price="item.tPrice"
            />
        </div>
        <div v-else-if="cardType === 'listHome'" :class="e('container')">
            <CpProductCardHomeStream
                v-for="item in limitedArray"
                :id="item.id"
                :key="item.id"
                :kind="parseInt(typeof item.offer.percent === 'string' ? item.offer.percent : '0') > 1 ? 'FeaturedOffer' : 'Featured'"
                :product-type="item.isEsd ? 'esd' : 'physical'"
                :pill-large="item.isEsd && getCardKind(item) === 'Standard'"
                :pill-short="item.isEsd && getCardKind(item) !== 'Standard'"
                :rate="item.rating.total > 0"
                :rate-value="item.rating.total"
                :reviews="item.questionsCount"
                :free-shipping="item.freeShipping"
                :link="item.link"
                :title="item.title"
                :has-discount="item.isOffer || item.tPrice > parsePriceInt(item.price)"
                :discount="item.offer.percent"
                :picture="item.picture"
                :image-title="item.title"
                :price="item.price"
                :t-price="item.tPrice"
            />
        </div>
        <div v-else-if="cardType === 'catalog'" :class="e('container')">
            <CpProductCardCatalog
                v-for="item in itemsCatalog"
                :id="item.id"
                :key="item.id"
                :kind="getCardKind(item)"
                :product-type="item.isEsd ? 'esd' : 'physical'"
                :pill-large="item.isEsd && getCardKind(item) === 'Standard'"
                :pill-short="item.isEsd && getCardKind(item) !== 'Standard'"
                :rate="item.rating.total > 0"
                :rate-value="item.rating.global"
                :reviews="item.rating.total"
                :free-shipping="item.freeShipping"
                :link="item.link"
                :title="item.title"
                :has-discount="item.isOffer || item.tPrice > parsePriceInt(item.price)"
                :discount="item.offer.percent"
                :picture="item.picture"
                :image-title="item.title"
                :price="item.price"
                :t-price="item.tPrice"
                :date-start="item.etaRange[0]"
                :date-end="item.etaRange[1]"
                :eta-data="etaData"
                eta
            />
        </div>
        <div v-else-if="cardType === 'catalogList'" :class="e('container-stream')">
            <CpProductCardCatalogStream
                v-for="(item, index) in itemsCatalog"
                :id="item.id"
                :key="item.id"
                :kind="getCardKind(item)"
                :product-type="item.isEsd ? 'esd' : 'physical'"
                :pill-large="item.isEsd && getCardKind(item) === 'Standard'"
                :pill-short="item.isEsd && getCardKind(item) !== 'Standard'"
                :rate="item.rating.total > 0"
                :rate-value="item.rating.global"
                :reviews="item.rating.total"
                :free-shipping="item.freeShipping"
                :link="item.link"
                :title="item.title"
                :has-discount="item.isOffer || item.tPrice > parsePriceInt(item.price)"
                :discount="item.offer.percent"
                :picture="item.picture"
                :image-title="item.title"
                :price="item.price"
                :t-price="item.tPrice"
                :date-start="item.etaRange[0]"
                :date-end="item.etaRange[1]"
                :eta-data="etaData"
                :index="index"
                eta
            />
        </div>

        <CpLoader kind="small" :class="e('loader')" v-if="showLoader"/>

        <div v-show="(button && label) || showLoadMoreButton" :class="e('button')">
            <CpButton
                :label="label ?? ''"
                :href="buttonHref"
                kind="label"
                color="primary"
                hollow
                full-width
                type="button"
                data-testid="section-strip-button"
                @click="$emit('buttonPress')"
                :without-border="showLoadMoreButton"
                :disabled="!showLoadMoreButton && !(button && label)"
            />
        </div>

        <div :class="e('previous-next-buttons')" v-show="showPreviousNextButtons">
          <CpButton
            label=""
            :href="previousPageLink"
            icon="chevron_left"
            kind="icon"
            color="primary"
            hollow
            type="button"
            without-border
            :disabled="!previousPageLink"
          />
          <CpButton
            label=""
            :href="nextPageLink"
            icon="chevron_right"
            kind="icon"
            color="primary"
            hollow
            type="button"
            without-border
            :disabled="!nextPageLink"
          />
        </div>
    </div>
</template>

<style scoped lang="scss">
    @import "assets/style/global.scss";
    .cp-section-strip {
        &__button {
          width: 100%;
          padding-left: $cp-gutter;
          padding-right: $cp-gutter;
          padding-bottom: v-bind(bottomPadding);
        }
        &__container {
          display: flex;
          gap: $cp-gutter;
          flex-wrap: wrap;
          padding: $cp-gutter;
        }
        &__container-stream {
          display: flex;
          gap: $cp-gutter;
          flex-wrap: nowrap;
          flex-direction: column;
          padding: $cp-gutter;
        }
        &__loader {
          margin: 0 auto;
          padding-top: 0;
        }
        &__previous-next-buttons {
          display: flex;
          justify-content: space-between;
          padding: 0 var(--cp-gutter) var(--cp-gutter) var(--cp-gutter);
        }
    }
</style>